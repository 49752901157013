<template lang="pug">
.box-lang-template
  .input-data(@click="showSelector = !showSelector")
    .group-name
      .flag.flag-icon(:class="selectedFlagClass")
      .language {{ selectedLangObject.name }}
    .transform-gpu.transition.transition-transform.ease-in-out(
      :class="{ '-rotate-180': showSelector }"
    )
      BaseCustomIcon(nameIcon="mdiChevronDown")
  XyzTransition(xyz="fade up-1 duration-3")
    .selector-language(v-if="showSelector")
      .option-box(
        v-for="item in languages_template",
        :key="item.id",
        @click="$emit('update:modelValue', item.id)"
      )
        .flag.flag-icon(:class="mountClass(item.id)")
        .language {{ item.name }}
</template>

<script>
import "flag-icon-css/css/flag-icons.min.css";
export default {
  name: "baseLanguageTemplateSelector",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: "en",
    },
  },
  data: () => ({
    showSelector: false,
    outsideCounterClick: 0,
    languages_template: [
      {
        name: "English",
        id: "en",
      },
      {
        name: "Deutsch",
        id: "de",
      },
      {
        name: "Italiano",
        id: "it",
      },
      {
        name: "Français",
        id: "fr",
      },
      {
        name: "Español",
        id: "es",
      },
      {
        name: "Português",
        id: "pt",
      },
      {
        name: "Nederland",
        id: "nl",
      },
      {
        name: "Polski",
        id: "pl",
      },
    ],
  }),
  watch: {
    showSelector: {
      inmediate: true,
      handler(nValue) {
        if (nValue) {
          window.addEventListener("click", this.setClick);
        } else {
          this.outsideCounterClick = 0;
          window.removeEventListener("click", this.setClick);
        }
      },
    },
  },
  methods: {
    setClick() {
      if (this.outsideCounterClick > 0) {
        this.showSelector = false;
      }
      this.outsideCounterClick++;
    },
    createFlagClass(id_flag) {
      return "flag-icon-" + id_flag;
    },
    createIdClass(id_flag) {
      return id_flag == "en" ? "us" : id_flag;
    },
    mountClass(id_flag) {
      const flag_id = this.createIdClass(id_flag);
      return this.createFlagClass(flag_id);
    },
  },
  computed: {
    selectedLangObject() {
      const lang = this.languages_template.find((l) => l.id == this.modelValue);
      return lang;
    },
    selectedFlagClass() {
      return this.mountClass(this.selectedLangObject.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-lang-template {
  @apply relative flex;
  max-width: 160px;
  width: 100%;

  .input-data {
    @apply w-full flex-grow flex items-center justify-between py-1 pl-2 pr-1 cursor-pointer bg-gray-100 rounded-md transition-all;

    &:hover {
      @apply bg-gray-200;
    }
  }

  .group-name {
    @apply flex items-center gap-2;
  }
}

.language {
  @apply text-sm overflow-ellipsis overflow-hidden;
}

.flag {
  @apply rounded-sm overflow-hidden flex-shrink-0;
  & * {
    margin: 0;
    padding: 0;
  }
}

.selector-language {
  @apply absolute top-full mt-1 w-full left-0 z-10 bg-gray-100 rounded-md border border-gray-200 shadow-md overflow-hidden;

  .option-box {
    @apply flex items-center py-1 px-2 gap-2 cursor-pointer;
    &:hover {
      @apply bg-blue-100;
    }
  }
}
</style>
