<template lang='pug'>
h1
  slot
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
h1 {
  @apply text-center;
  @apply text-3xl;
  @apply font-bold;
  @apply text-gray-700;
}
</style>