<template lang="pug">
routerView
</template>

<script>
import { availableLanguages, loadLocaleMessages } from "@/languages";
export default {
  beforeCreate() {
    this.$store.dispatch("TemplatesStore/retrieveListTemplates");
    this.$store.dispatch("ProductsStore/retrieveListproducts");
  },
  created() {
    this.$store.dispatch("UserStore/loadMeData");
  },
  methods: {
    setHeaderWebPage(title) {
      document.title = title;
    },
  },
  watch: {
    $route(to) {
      if (to.query.locale && availableLanguages.includes(to.query.locale)) {
        loadLocaleMessages(to.query.locale);
      }
      //this.setHeaderWebPage(this.$t(`metaheader.${to.name}`));
    },
  },
};
</script>
