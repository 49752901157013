<template lang="pug">
.baseTextArea
  FormsBaseLabel(text="Descripción:")
  vueEditor.mt-1(
    :editorToolbar="customToolbar",
    theme="",
    v-model="content_computed"
  )
</template>

<script>
import { VueEditor } from "vue3-editor";

export default {
  name: "baseTextArea",
  emits: ["update"],
  components: {
    VueEditor,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "center" }],
      [{ align: "right" }],
      [{ color: [] }], // dropdown with defaults from theme
      [{ direction: "rtl" }], // text direction
    ],
  }),
  computed: {
    content_computed: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.quillWrapper {
  @apply rounded-lg border overflow-hidden bg-white;

  &:deep(.ql-toolbar.ql-snow) {
    @apply bg-gray-100 relative;

    &::before {
      content: "";
      @apply left-0 bottom-0 absolute h-px bg-gray-200 w-full;
    }

    svg > * {
      &.ql-stroke,
      &.ql-fill {
        // @apply stroke-current;
      }
    }
  }

  &:deep(.ql-toolbar),
  &:deep(.ql-container) {
    @apply border-none;
  }

  &:deep(.ql-editor) {
    min-height: 150px;
  }
}
</style>
