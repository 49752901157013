import { createI18n } from 'vue-i18n';

import messages from './locales/en';

const loadedLanguages = ['en']; // our default language that is preloaded
export const availableLanguages = [
  'es',
  'en',
  'de',
  'it',
  'fr',
  'pt',
  'nl',
  'pl'
]; //

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: messages
  }
});

export function setConfi18n() {
  let navlanguage =
    localStorage.getItem('cv-locale') || navigator.language.slice(0, 2);
  loadLocaleMessages(navlanguage);
  return i18n;
}

export function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  localStorage.setItem('cv-locale', lang);
}

export async function loadLocaleMessages(locale) {
  // load locale messages
  if (i18n.locale === locale || loadedLanguages.includes(locale)) {
    setI18nLanguage(locale);
  } else {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    );
    i18n.global.setLocaleMessage(locale, messages.default);
    loadedLanguages.push(locale);
    setI18nLanguage(locale);
  }
}
