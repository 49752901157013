<template lang="pug">
.inline-block
  router-link(to="/")
    .flex.items-center 
      img.w-6.mr-3(src="/theme/icon.svg")
      h1.font-bold.text-xl.text-gray-600 bestcv
        span.font-normal.text-gray-400 .app
</template>
<script>
export default {
  name: "logoWeb",
};
</script>
<style lang="scss" scoped>
</style>