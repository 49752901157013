const state = {
  showModalNewAccount: false,
  forceErrorPayment: false
};

const mutations = {
  showModal(state) {
    state.showModalNewAccount = true;
  },
  ActiveErrorPayment(state) {
    state.forceErrorPayment = true;
  }
};
const actions = {};

const getters = {
  showModalNewAccount: state => state.showModalNewAccount,
  forceErrorPayment: state => state.forceErrorPayment
};

export const WebOptionsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
