import { createApp } from 'vue';
import { registerComponents } from './plugins/loaderBaseComponents';
import App from './App.vue';
import router from './router';
import store from './store';
import { setConfi18n } from './languages';

import 'tailwindcss/tailwind.css';
import './assets/styles/tailwind.css';

import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core'; // Import css here if you haven't elsewhere

import ObserverFormSync from '@/plugins/observerFormSync';

const app = createApp(App);

registerComponents(app);

//listado de registro de componentes
//console.log(app._context.components);

app.use(setConfi18n()).use(store).use(router).use(VueAnimXyz).use(ObserverFormSync).mount('#app');
