<template lang="pug">
.flex.flex-col.w-full.relative
  FormsBaseLabel(:text="label", v-if="label")
  .input-text.flex.justify-between.items-center.cursor-pointer(
    @click="show = !show"
  )
    span {{ modelValue.text }}
    .transform-gpu.transition.transition-transform.ease-in-out(
      :class="{ '-rotate-180': show }"
    )
      BaseCustomIcon.text-blue-500(nameIcon="mdiChevronDown")

  XyzTransition(xyz="fade up-1 duration-1")
    .box-select(v-show="show", :class="position")
      ul
        li(
          v-for="option in listOptions",
          :key="option.value",
          @click="setOption(option)"
        ) {{ option.text }}
</template>

<script>
export default {
  name: "baseInputSelect",
  emits: ["update:modelValue"],
  props: {
    listOptions: {
      type: Array,
    },
    modelValue: {
      type: Object,
    },
    position: {
      type: String,
      default: "top", //top, bottom
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      outsideCounterClick: 0,
    };
  },
  methods: {
    setOption(value) {
      this.show = false;
      this.$emit("update:modelValue", value);
    },
    setClick() {
      if (this.outsideCounterClick > 0) {
        this.show = false;
      }
      this.outsideCounterClick++;
    },
  },
  watch: {
    show: {
      inmediate: true,
      handler(nValue) {
        if (nValue) {
          window.addEventListener("click", this.setClick);
        } else {
          this.outsideCounterClick = 0;
          window.removeEventListener("click", this.setClick);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./inputs-style.scss";
ul {
  @apply p-2;
  li {
    &:hover {
      @apply bg-blue-50 cursor-pointer font-bold;
    }
    @apply py-3 px-4 text-gray-600 font-medium rounded-lg transition-colors;
  }
}

.box-select {
  @apply flex flex-col z-50 bg-white shadow-xl rounded-xl absolute w-full overflow-hidden origin-bottom border border-gray-100 max-h-80 overflow-y-auto;

  &.top {
    @apply bottom-14;
    @apply origin-bottom;
  }
  &.bottom {
    @apply origin-top;
    top: 100%;
    margin-top: 0.2rem;
  }
}
</style>
