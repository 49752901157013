<template lang="pug">
.button-wrapper(@click="showColors = !showColors")
  .button Colores
  XyzTransition(xyz="fade down-1 duration-1")
    .baseSelectColors(v-if="showColors")
      .list-colors
        .group-colors(
          v-for="(color, index) in listColorsParsed",
          :key="index",
          @Click="$emit('selectedColor', color)",
          :class="{ selected: color.selected }"
        )
          .color(:style="`background-color: ${color.color1}`")
          .color(:style="`background-color: ${color.color2}`")
</template>

<script>
export default {
  emits: ["selectedColor"],
  props: {
    options_colors: {
      type: Array,
      required: true,
    },
    color_selected: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showColors: false,
    outsideCounterClick: 0,
  }),
  computed: {
    listColorsParsed() {
      return this.options_colors.map((c) => {
        let selected = false;
        if (
          c.color1 == this.color_selected.color1 &&
          c.color2 == this.color_selected.color2
        )
          selected = true;
        return {
          color1: c.color1,
          color2: c.color2,
          selected,
        };
      });
    },
  },
  methods: {
    setClick() {
      if (this.outsideCounterClick > 0) {
        this.showColors = false;
      }
      this.outsideCounterClick++;
    },
  },
  watch: {
    showColors: {
      inmediate: true,
      handler(nValue) {
        if (nValue) {
          window.addEventListener("click", this.setClick);
        } else {
          this.outsideCounterClick = 0;
          window.removeEventListener("click", this.setClick);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.baseSelectColors {
  @apply absolute p-2 rounded-md border border-blue-400 bottom-0 mb-14 w-full bg-white cursor-default;
}

.list-colors {
  @apply flex flex-col;
  .group-colors {
    @apply flex overflow-hidden rounded-sm cursor-pointer;
    .color {
      @apply h-4 w-full;
    }

    & + .group-colors {
      @apply mt-2;
    }
    &.selected {
      @apply ring-4 ring-blue-400  cursor-default;
    }
  }
}
.button-wrapper {
  @apply flex bg-red-50 h-full relative;
}
.button {
  @apply relative flex-grow h-full bg-blue-50 flex items-center justify-center rounded-md cursor-pointer transition-colors text-blue-400 border border-blue-400 text-center text-sm;
  &:hover {
    @apply bg-blue-100 text-blue-500;
  }
}
</style>
