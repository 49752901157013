import { ProductsRepository } from '@/repository/products.repository';

const state = {
  products: []
};

const mutations = {
  updateProducts(state, payload) {
    state.products = payload;
  }
};
const actions = {
  async retrieveListproducts({ commit }) {
    const listproductsApiResponse = await ProductsRepository.getListProducts();
    console.log(listproductsApiResponse);
    commit('updateProducts', listproductsApiResponse);
  }
};
const getters = {
  getproductDefault: state => state.products[0],
  getListProducts: state => state.products
};

export const ProductsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
