<template lang="pug">
XyzTransition(xyz="fade")
  .wrapper-loader(v-if="show")
    .bg-loader
    .box-spinner.text-blue-400
      //--BaseCustomIcon.animate-spin(nameIcon="mdiCogOutline", :size="48")
</template>

<script>
export default {
  name: "baseGlobalLoader",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-loader {
  @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center;

  .bg-loader {
    @apply w-full h-full absolute bg-white opacity-60;
  }
}
</style>
