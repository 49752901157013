import ModelProfile from '../models/profile.model';
import ModelStudies from '../models/studies.model';
import ModelCompetences from '../models/competences.model';
import ModelCourses from '../models/courses.model';
import ModelCustom from '../models/custom.model';
import ModelCustomItems from '../models/custom.item.model';
import ModelExperience from '../models/experience.model';
import ModelHobbie from '../models/hobbie.model';
import ModelLanguages from '../models/languages.model';
import ModelReferences from '../models/references.model';

import router from '@/router/index';
import { UsersRepository } from '@/repository/users.repository';

const repositoryModels = {
  ModelProfile,
  ModelCompetences,
  ModelCourses,
  ModelCustom,
  ModelCustomItems,
  ModelExperience,
  ModelHobbie,
  ModelLanguages,
  ModelReferences,
  ModelStudies
};

export const serviceFactory = name => repositoryModels[name];

const state = () => ({
  isSync: false,
  isLoading: true,
  curriculum_id: null,
  template_conf: {
    template_id: null,
    colors: null,
    font: 'Roboto',
    lang: 'es'
  },
  personal_data: {
    profile_image: '',
    profile_name: '',
    profile_post_name: '',
    profile_phone: '',
    profile_email: '',
    profile_profession: '',
    address_direction: '',
    address_postal_code: '',
    address_city: '',
    address_country: '',
    profile_birth_date: '',
    profile_birth_location: '',
    profile_nationality: '',
    profile_genre: {
      id: 0
    },
    profile_vehicle_permision: '',
    profile_civil_state: '',
    profile_web: '',
    profile_linkedin: '',
    profile_instagram: ''
  }
});

const mutations = {
  async updatePofile(state, payload) {
    state.personal_data = payload;
  },
  async updateIsSync(state, payload) {
    state.isSync = payload;
  },
  async updateIsLoading(state, payload) {
    state.isLoading = payload;
  },
  async updateTemplateConf(state, payload) {
    state.template_conf = payload;
  },
  async updateTemplateLang(state, payload) {
    state.template_conf.lang = payload;
  },
  async updateColorSelected(state, payload) {
    state.template_conf.colors = payload;
  },
  async updateFontselected(state, payload) {
    state.template_conf.font = payload;
  },
  async updateTemplateSelected(state, payload) {
    state.template_conf.template_id = payload;
  },
  async updateCurriculumID(state, payload) {
    state.curriculum_id = payload;
  },
  async resetState(state) {
    state.curriculum_id = null;
    state.template_conf = {
      template_id: null,
      colors: null,
      font: 'Roboto',
      lang: 'en'
    };
    state.personal_data = {
      profile_image: '',
      profile_name: '',
      profile_post_name: '',
      profile_phone: '',
      profile_email: '',
      profile_profession: '',
      address_direction: '',
      address_postal_code: '',
      address_city: '',
      address_country: '',
      profile_birth_date: '',
      profile_birth_location: '',
      profile_nationality: '',
      profile_genre: {
        id: 0
      },
      profile_vehicle_permision: '',
      profile_civil_state: '',
      profile_web: '',
      profile_linkedin: '',
      profile_instagram: ''
    };
  }
};

const getters = {
  getProfilePersonalDataState: state => state.personal_data,
  getIsSync: state => state.isSync,
  getIsLoading: state => state.isLoading,
  getTemplateId: state => state.template_conf.template_id,
  getColorsTempalte: state => state.template_conf.colors,
  getFontTemplate: state => state.template_conf.font,
  getCurriculumId: state => state.curriculum_id
};

const actions = {
  async changeTemplateSelected({ commit, dispatch, rootGetters }, payload) {
    commit('updateTemplateSelected', payload);
    const templateSelected = rootGetters['TemplatesStore/getSelectedTemplate'];
    commit('updateColorSelected', templateSelected.default_colors);
    const existDataStorage = window.localStorage.getItem('resume');
    if (existDataStorage) {
      await dispatch('mountStoreCurriculum', { dataStorageString: existDataStorage, setTemplate: false });
      await dispatch('syncCV', { draw: false });
    }
  },
  async loadCV({ dispatch, commit }, { curriculum_id, cvData }) {
    commit('updateCurriculumID', curriculum_id);
    window.localStorage.removeItem('resume');
    if (!cvData) return dispatch('createCV');
    window.localStorage.setItem('resume', cvData);
    await dispatch('mountStoreCurriculum', { dataStorageString: cvData });
    dispatch('DataCvViewerStore/printCv', cvData, { root: true });
    commit('updateIsLoading', false);
  },

  async createCV({ dispatch, commit, rootGetters }) {
    console.log('createCV');
    const existDataStorage = window.localStorage.getItem('resume');
    if (existDataStorage) {
      await dispatch('mountStoreCurriculum', { dataStorageString: existDataStorage });
      dispatch('DataCvViewerStore/printCv', existDataStorage, { root: true });
    } else {
      await dispatch('OrderCvComponentsStore/changeOrderComponents', ['profileComponent', 'studiesComponent', 'experienceComponent'], {
        root: true
      });
      const templateSelected = rootGetters['TemplatesStore/getSelectedTemplate'];
      console.log(templateSelected);
      if (!templateSelected) {
        await dispatch('changeTemplateSelected', 1);
      }
      dispatch('syncCV', { draw: true });
    }
    commit('updateIsLoading', false);
  },
  async mountStoreCurriculum({ dispatch, commit }, { dataStorageString, setTemplate = true }) {
    const dataStorage = JSON.parse(dataStorageString);
    await dispatch('OrderCvComponentsStore/changeOrderComponents', dataStorage.ComponentsOrder, {
      root: true
    });
    await commit('updatePofile', dataStorage.ProfileData);
    if (setTemplate) await commit('updateTemplateConf', dataStorage.TemplateConf);
    for await (const iterator of Object.keys(repositoryModels)) {
      await serviceFactory(iterator).create({
        data: [...dataStorage[iterator]]
      });
    }
  },
  async getFullCVJson({ state, rootGetters }) {
    const personal_data = state.personal_data;
    const template_conf = state.template_conf;
    const jsonCvData = {
      ProfileData: personal_data,
      TemplateConf: template_conf
    };
    for await (const iterator of Object.keys(repositoryModels)) {
      const data = serviceFactory(iterator).all();
      jsonCvData[iterator] = data.map(({ $id, id, ...data }) => data);
    }
    const ComponentsOrder = rootGetters['OrderCvComponentsStore/getOrder'];
    jsonCvData['ComponentsOrder'] = ComponentsOrder;
    return JSON.stringify(jsonCvData);
  },
  async syncCV({ commit, dispatch, rootGetters, state }, { draw }) {
    const dataParsed = await dispatch('getFullCVJson');
    window.localStorage.setItem('resume', dataParsed);
    if (draw) await dispatch('DataCvViewerStore/printCv', dataParsed, { root: true });
    commit('updateIsSync', false);

    if (rootGetters['UserStore/getIsLogged'] && state.curriculum_id && rootGetters['DataCvViewerStore/cvParsed']) {
      await UsersRepository.updateMeCurriculum(state.curriculum_id, {
        pdf_base64: rootGetters['DataCvViewerStore/cvParsed'],
        curriculum_data: dataParsed,
        snapshot_base64: rootGetters['DataCvViewerStore/cvSnapshot']
      });
    }
  },
  async modStateSync({ commit }, payload) {
    commit('updateIsSync', payload);
  },
  async deleteStoreCurriculum({ commit, dispatch }) {
    for await (const iterator of Object.keys(repositoryModels)) {
      serviceFactory(iterator).deleteAll();
    }
    window.localStorage.removeItem('resume');
    await commit('DataCvViewerStore/resetState', null, { root: true });
    commit('resetState');
  }
};

export const DataCvBuilderStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
